@import "vars";

.persons-list-filter {
  .btn {
    padding: $content-switcher-btn-padding;
  }
}

.persons-list {
  padding: 0;

  > li {
    padding: 0;

    > div {
      border: 1px solid $persons-border;
      margin: 5px;
      min-height: 120px;

      > a {
        display: inline-block;
        height: 100%;
        min-height: 100%;
        padding: 5px;
        width: 100%;
        color: inherit;
        text-decoration: none;

        &:visited, &:hover, &:active {
          color: inherit;
          text-decoration: none;

        }
      }
    }
  }

  .organization, .role, .date {
    color: $persons-list-light-text;
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.membership-info {
  &::before {
    content: '|';
  }

  &:first-child::before {
    content: '';
  }
}

.person-section-mentions, .person-section-memberships, .person-section-papers {
  margin-top: $default-section-spacing;
}
