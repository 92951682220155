@import "vars";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "markercluster.theme";

// Color schema inspired by http://www.colourlovers.com/palette/580974/Adrift_in_Dreams

.index-v2 {
  .home-container {
    padding: $index-section-default-padding 0;
  }
  .home-container-title {
    min-height: 50vh;
    display: flex;
    align-items: center;
    background-color: $index-title-section-background;
    .title-colors {
      color: $index-title-color;
      background-color: $index-title-background;
    }
  }
  .home-container-search {
    background-color: $index-search-section-background;
    color: $index-search-text;
  }
  .home-container-map {
    background-color: $index-map-section-background;
    .title-colors {
      display: inline-block;
      color: $index-map-title-color;
      background-color: $index-map-title-background;
    }
    padding-bottom: $index-section-default-padding * 2; // Some additional padding comes from the map background
  }
  .home-container-main-buttons {
    background-color: $index-main-buttons-section-background;
    .title-colors {
      display: inline-block;
      color: $index-main-buttons-title-color;
      background-color: $index-main-buttons-title-background;
    }
    .main-button-squared {
      position: relative;
      width: 100%;
      max-width: 200px;
      display: block;
      background-color: $index-main-buttons-btn-background;
      color: $index-main-buttons-btn-text;

      @extend .shadow;

      &:before {
        // Hack to enforce a square form
        content: "";
        display: block;
        padding-top: 100%;
      }

      &:hover, &:active {
        background-color: darken($index-main-buttons-btn-background, 8%);
      }

      .icon {
        display: block;
        text-align: center;
        font-size: 60px;
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 40px);
      }

      .text {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }
  .home-container-meetings {
    background-color: $index-meetings-section-background;
    .title-colors {
      display: inline-block;
      color: $index-meetings-title-color;
      background-color: $index-meetings-title-background;
    }
    .meeting-button {
      background-color: $index-meetings-btn;
      color: #fff;
      text-align: left;
      &:hover, &:active {
        background-color: darken($index-meetings-btn, 5%);
      }
      .col {
        padding-left: 10px;
        padding-right: 10px;
      }
      > div {
        padding: 10px;
      }
      .title {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: .5rem;
      }
    }
  }
  .home-container-documents {
    background-color: $index-documents-section-background;
    .title-colors {
      display: inline-block;
      color: $index-documents-title-color;
      background-color: $index-documents-title-background;
    }
  }

  .more-info-link {
    @extend .lead;
    text-align: right;
    a, a:link, a:visited {
      color: #005abf;
    }
  }
}

.search-autocomplete-home {
  span.twitter-typeahead {
    z-index: $typeahead-zindex;
  }
}

#leaflet-map {
  height: $index-map-height;
  // The form-control-focus doesn't work with the shadow class as the latter takes precedent with `!important`,
  // so we roll our own
  box-shadow: $box-shadow;
  &:focus {
     box-shadow: $box-shadow, $input-box-shadow, $input-focus-box-shadow;
  }

  .leaflet-popup-content-wrapper {
    .type-address {
      font-size: 12px;
      display: flex;

      .type {
        flex: 1;
      }

      .address {
        text-align: right;
        flex: 1;
      }

      padding-bottom: 5px;
      border-bottom: solid 1px #ccc;
    }

    .paper-title {
      padding-bottom: 5px;
      margin-top: 5px;
      border-bottom: solid 1px #ddd;
      position: relative;

      .icon {
        float: left;
        margin-top: 4px;
        font-size: 1.2em;
      }

      a {
        max-height: 2.8em; // line-height * 2
        overflow: hidden;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2; // WTF
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }

    ul.files {
      list-style-type: none;
      display: block;
      margin: 0;
      padding: 0;

      li {
        display: block;
        padding: 0;
        margin: 5px 0 0 18px;
        text-indent: -15px;
      }
    }

    .location-name {
      float: right;
    }
  }
}

.leaflet-container {
  .leaflet-control-attribution a {
    color: #333;
  }
  .map-incative-hint {
    color: #555;
    font-style: italic;
  }
}

.leaflet-bar a.leaflet-disabled {
  color: #707070;
}

.home-container-meetings {
  .meeting-col {
    .btn {
      white-space: normal;
    }
    display: flex;
  }
}
