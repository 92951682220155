.file-header-small {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    display: flex;
  }

  // We need to be space efficient on that page, so we remove the default top margin
  margin-top: -#{$main-content-margin-top};
  @extend .py-3;

  > div {
    > div {
      display: inline-block;

      &:after {
        @extend .mx-2;
        content: '/';
      }

      &:last-child:after {
        @extend .mx-0;
        content: '';
      }
    }

    .dropdown-menu {
      max-height: 300px;
      overflow-y: scroll;
    }
  }

  .files-dropdown {
    .dropdown-item {
      font-size: 0.9rem;
      max-width: 320px;
      @media screen and (max-width: 350px) {
        max-width: 240px;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.file-main-content {
  height: calc(100vh - #{$header-height} - 2 * #{$main-content-margin-top});
  display: flex;
  flex-direction: column;

  .pdf-wrapper {
    height: 100%;
    flex: 1;

    $file-disclaimer-height: 30px;

    > .embed-responsive {
      height: calc(100% - #{$file-disclaimer-height});
    }

    > .file-disclaimer {
      height: $file-disclaimer-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // The main-content has a bottom margin which would be difficult to change, so the disclaimer is move into the
      // margin to look more spaced vertically
      transform: translateY(12px);
    }
  }

  .file-image {
    max-width: 100%;
    margin: 0 auto;
  }
}

.txt-wrapper .embed-responsive {
  overflow-x: scroll;
  border-color: $gray-800;
  @extend .px-3;
}

.file-content-div {
  width: $container-width;
  max-width: 100%;
}

.file-layout-container {
  @media (max-width: $container-width) {
    .col {
      display: none;
    }
  }

  // This gives a nice two pane layout on medium-sized screens
  @media (max-width: $container-width * 2) and (min-width: breakpoint-max(lg, $grid-breakpoints)) {
    .right-counterweight {
      display: none;
    }
  }
}

.file-switcher {
  max-width: $container-width;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }

  .title-back {
    @extend .h3;

    display: table; // Force text to wrap
    width: 100%;
    table-layout: fixed;

    > a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $body-color;
    }
  }

  .paper-information {
    @extend .mb-3;

    .col {
      text-align: center;
      &:first-child {
        text-align: left;
        padding-left: calc(#{$grid-gutter-width} / 2 + #{$list-group-item-padding-x}); // Align with rest of the items
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  .paper-link-row {
    .col {
      text-align: left;
      padding-left: calc(#{$grid-gutter-width} / 2 + #{$list-group-item-padding-x}); // Align with rest of the items
      display: table;
      width: 100%;
      table-layout: fixed;
      a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .file-holder-outer {
    // Don't ask me why this works
    // https://stackoverflow.com/a/27461685/3549270
    overflow: hidden;
    flex-grow: 1;
    position: relative;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  }
}
