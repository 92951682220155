@import "vars";

#main-content {
  margin: $main-content-margin-top auto;
  &.no-padding {
    margin: 0;
  }
  min-height: calc(100vh - #{$header-height} - #{$footer-height} - 2 * #{$main-content-margin-top});
  hyphens: auto;
}

.no-link-color {
  // ideally, this would only remove the style of the a, but there's nothing avaible for that right now
  color: black;
}

.fa-custom {
  @extend .fa;
  @extend .fa-fw;
  @extend .mr-1;
  @extend .no-link-color;
}

#main-menu {
  .navbar-toggler {
    border: none;
    outline: 0;
  }
  .logout-button {
    padding: 0;
    text-align: left;
  }
  @media screen and (max-width: map_get($grid-breakpoints, 'md') - 1) {
    .navbar-brand {
      flex: 1; // Pushes the search-button to the right
    }
    .search-autocomplete {
      display: none;
    }
  }
}
.navbar-footer-disclaimer {
  padding-right: $navbar-nav-link-padding-x;
  padding-left: $navbar-nav-link-padding-x;
  color: $navbar-dark-color;
  font-size: 0.8em;
}

.btn-transparent {
  background-color: transparent;
}

// These can't be in the calendar.scss because they need to access variables from bootstrap
#calendar-loading-spinner {
  font-size: $h2-font-size;
  line-height: $headings-line-height;
}

// Sometimes you want a div that's styled like a p
.paragraph {
  @extend p;
}

footer {
  min-height: $footer-height;
}