@import "vars";

.agenda {
  dt {
    min-width: 30px;
    // For some reason min-width sets width, which can apparently be circumvented with diplay: table
    display: table;
  }

  .additional-links {
    display: block;
    font-size: 0.8em;
  }
}

.meeting-section {
  margin-bottom: $default-section-spacing * 0.5;
}

.meeting-vertical-bar {
  @include media-breakpoint-up(md) {
    border-top: 1px solid $hr-border-color;
    border-bottom: 1px solid $hr-border-color;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .std-element {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: $font-size-sm; // Same size as the location button
  }

  div.location-dropdown {
    padding-top: 0.25rem;
    padding-bottom: 0;

    .dropdown-toggle {
      padding-left: 0;
    }

    .dropdown-menu {
      padding: 0;
      width: 420px;
      max-width: calc(100vw - #{$default-section-spacing});
    }

    .location-map {
      width: 100%;
      height: 100%;
    }

    .dynamic-location-modal {
      height: 600px;
      max-height: calc(100vh - 4em); // 2em is the padding of the modal
    }
  }
}

// https://stackoverflow.com/a/13184714/3549270
.offsetted-anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}

// The offsetted anchor from above is selected by :target
:target + div {
  box-shadow: $box-shadow;
}
