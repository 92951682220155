$footer-height: 96px !default;
$header-height: 56px !default;
$header-search-max-width: 200px !default;
$main-content-margin-top: 40px !default;
$badge-border-radius: .25rem !default;
$border-radius: 0 !default;
$text-muted: #6e7881 !default; // Should have a contrast of at least 4.5
$link-color: #0072ee !default; // Should have a contrast of at least 4.5
$fa-font-path: "~font-awesome/fonts/" !default;
$container-width: 800px !default;
$default-section-spacing: 40px !default;

$container-max-widths: (
  md: $container-width,
) !default;

// Color aliases; hardcoded, as _variables.scss of bootstrap is not yet loaded here
$navbar-dark-color: #a0a3a5;
$primary: #0072ed !default; // A little bit darker than default, to ensure enough contrast
$theme-colors: (
  "navbar": #343a40, // $gray-800
  "content-switcher": #6f777f,
) !default;

// There's one element with 800 and an outer with 10000
$leaflet-controls-zindex: 1000 !default;
$typeahead-zindex: $leaflet-controls-zindex + 10 !default;
$search-icon-zindex: $typeahead-zindex + 10 !default;
$zindex-dropdown: $typeahead-zindex + 1 !default; // The User-menu should be above the search on the home page
$content-switcher-btn-padding: .375rem .75rem !default;
$persons-border: #6c757d !default; // $gray-600
$persons-list-light-text: #727272 !default; // Hint: needs enough contrast to the background
$calendarEventBackground: #3a87ad !default;

// Heading size: one step smaller than bootstrap's default
$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;


// Home page
$index-section-default-padding: $main-content-margin-top !default;

$index-title-section-background: #b8dded !default;
$index-title-color: #000 !default;
$index-title-background: transparent !default;

$index-search-section-background: #15167e !default;
$index-search-text: white !default;

$index-map-section-background: #f9f7f5 !default; // Should be similar to the map background; #D4D8BB
$index-map-title-color: #111 !default;
$index-map-title-background: transparent !default;
$index-map-height: 460px !default;

$index-main-buttons-section-background: #ddd !default;
$index-main-buttons-title-color: #111 !default;
$index-main-buttons-title-background: transparent !default;
$index-main-buttons-btn-background: #0B486B !default;
$index-main-buttons-btn-text: white !default;;

$index-meetings-section-background: #fff !default;
$index-meetings-title-color: #111 !default;
$index-meetings-title-background: transparent !default;
$index-meetings-btn: #308282 !default;

$index-documents-section-background: #A8DBA8 !default;
$index-documents-title-color: #111 !default;
$index-documents-title-background: transparent !default;
