form.login {
  .signup {
    text-align: right;
    margin-bottom: 20px;
  }
}

.mst-container-account {
  .mst-account-section + .mst-account-section {
    margin-top: 50px;
  }
}

.mst-email-confirm {
  .email-list {
    .address {
      font-weight: bold;
    }
    .verified, .unverified, .primary {
      font-size: 0.8em;
    }
  }
  .email-actions {
    margin-top: 20px;
  }
  .email-add-row h2 {
    padding-top: 0.5rem;
    font-size: inherit;
  }
}

.login-container {
    max-width: 400px;
}

.allauth-wrapper {
  .facebook {
    background-color: #3b5998;
    color: white;
  }

  .twitter {
    background-color: #005fd1;
    color: white;
  }
}

.errorlist {
  @extend .list-unstyled;
  color: red;
}