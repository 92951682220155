@import "~bootstrap/scss/bootstrap";

.card {
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.5rem;
  }
  .card-header {
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
  }
}

.nav-link.active:focus {
  background-color: darken($nav-pills-link-active-bg, 5%);
}
