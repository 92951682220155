@import "vars";
@import "daterangepicker";

.navbar .search-autocomplete .form-control {
  max-width: $header-search-max-width;
}

.search-autocomplete {
  position: relative;

  input {
    padding-right: 30px;
  }
}

.search-icon {
  position: absolute;
  top: calc(50% - 16px / 2); // font awesome says 14px, but `font-size: inherit;` seems to override that
  right: calc((#{$input-height} - 16px) / 2);
  border: 0;
  padding: 0;
  background: none;
  z-index: $search-icon-zindex;
  cursor: pointer;
}

.daterangepicker {
  padding-top: 0;
  padding-bottom: 0;
}

#detailed-searchform {
  margin-bottom: 30px;
  border-bottom: solid 1px $card-border-color;
}

.search-facet {
  @extend .pt-2;

  .discard-btn {
    @extend .btn-danger;
    width: 50%;
  }

  .select-btn {
    @extend .btn-primary;
    width: 50%;
  }

  &.search-sort {
    flex: 1;
    text-align: right;
  }

  label {
    cursor: pointer;
  }
}

.search-facet-location {
  .location-select-map {
    height: 400px;
  }

  .dropdown-menu {
    width: 520px;
    padding: 0;
  }

  .location-description {
    vertical-align: bottom;

    > * {
      display: inline-block;
      vertical-align: bottom;
    }

    .location {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .distance-select {
    label {
      margin: 0;
      width: 100%;
      text-align: left;
    }

    > * {
      width: 50%;
    }

    :first-child {
      border-left: 0;
    }

    :last-child {
      border-right: 0;
    }
  }

  .radius-select-row {
    @extend .m-1;
  }
}

.search-facet-filter-dropdown {
  .filter-list {
    max-width: 400px;

    a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dropdown-menu {
    padding-top: 0;
    min-height: 5rem;
    max-width: 100vw;
  }

  .dropdown-scroller {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }

  .dropdown-item {
    padding: $dropdown-item-padding-y calc(#{$input-btn-padding-x} + 2px); // align with search filter
  }

  .dropdown-filter {
    padding: 0;
    border-bottom: 1px solid $card-border-color;

    input {
      border: none;
    }
  }

  .remove-filter {
    border-bottom: 1px solid $card-border-color;

    a:hover {
      text-decoration: none;
    }
  }
}

.facet-dropdown {
  .dropdown-menu {
    padding-bottom: 0;
  }

  .remove-filter {
    padding: 0;
  }
}

.results-list {
  list-style: none;
  padding-left: 0;

  .result {
    margin-top: 15px;

    &:hover {
      box-shadow: $box-shadow;
    }

    i {
      color: map_get($theme-colors, dark);
    }

    .col {
      padding: 0;
      min-width: 0; // Workaround for word-wrapping-problem: https://css-tricks.com/flexbox-truncated-text/
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .results-small {
    font-size: 0.9rem;
    color: #414141;

    mark {
      font-weight: bold;
    }
  }
}

.search-refreshing-spinner {
  font-size: $h2-font-size;
}
